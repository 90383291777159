import ComplexMultiplier from '@/interfaces/complexMultiplier.interface';
import { Origins } from '@/enums/origins.enum';

export default {
    /**
     * Format amount.
     *
     * @param {number | [number, number]} value
     * @return {string}
     */
    amount(value: number | [number, number]): string {
        if (typeof value === 'object') {
            return `${value[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}-${value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    /**
     * Format millions.
     *
     * @param {number | [number, number]} value
     * @return {string}
     */
    millions(value: number | [number, number]): string {
        if (typeof value === 'object') {
            return `${(Math.abs(Number(value[0])) / 1.0e6).toFixed(1).toString().replace('.', ',')}-${(Math.abs(Number(value[1])) / 1.0e6).toFixed(1).toString().replace('.', ',')}`;
        }

        return `${(Math.abs(Number(value)) / 1.0e6).toFixed(1).toString().replace('.', ',')}`;
    },

    prettifyHourMinute(value: number): string {
        let hourValue = value
            .toString()
            .substring(0, value.toString().length - 2);
        let minValue = `${parseInt(value.toString().substring(value.toString().length - 2)) * 0.6}`;

        if (minValue.toString().length < 2) {
            minValue = `0${minValue}`;
        }

        return `${hourValue}:${minValue}`;
    },

    firstName(value: string): string {
        const names = value.trim().split(' ');
        if (names.length < 2) {
            return value;
        }

        return names.slice(0, -1).join(' ');
    },

    lastName(value: string): string {
        const names = value.trim().split(' ');
        if (names.length < 2) {
            return '';
        }

        return names[names.length - 1];
    },

    capitalize(word: string) {
        return word[0].toUpperCase() + word.substring(1);
    },

    dateShort(date: Date): string {
        return date.toLocaleString('da-DK', { day: 'numeric' });
    },

    dayNameLong(date: Date) {
        return date.toLocaleString('da-DK', { weekday: 'long' });
    },

    dayNameShort(date: Date): string {
        return date.toLocaleString('da-DK', { weekday: 'short' });
    },

    monthShort(date: Date): string {
        return date
            .toLocaleString('da-DK', { month: 'short' })
            .replace('.', '');
    },

    monthLong(date: Date): string {
        return date.toLocaleString('da-DK', { month: 'long' });
    },

    hourMinute(date: Date): string {
        return date
            .toLocaleString('da-DK', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            })
            .replace('.', ':');
    },

    year(date: Date): string {
        return date.toLocaleString('da-DK', { year: 'numeric' });
    },

    formatMindworkingUrl(
        url: string,
        width: string,
        height: string,
        mode?: string,
    ) {
        return `${url}${width ? `&width=${width}` : ''}${height ? `&height=${height}` : ''}${mode ? `&mode=${mode}` : ''}`;
    },

    formatMindworkingUrlToCDN(
        url: string,
        width: string,
        height: string,
        mode?: string,
    ) {
        let cdn = '';
        const origin = window.location.origin;

        switch (origin) {
            case Origins.TST:
            case Origins.UAT:
            case Origins.PRD:
                cdn = `${window.location.protocol}//cdn.${window.location.host}`;
                break;
            default:
                cdn = 'https://cdn.dev.danbolig.dk';
        }

        return `${cdn}/image?imgurl=${url}${width ? `&width=${width}` : ''}${height ? `&height=${height}` : ''}${mode ? `&mode=${mode}` : ''}`;
    },

    complexInterval(
        intervals: ComplexMultiplier[],
        min: number,
        max: number,
    ): number[] | undefined {
        let complexInterval: number[] = [];

        intervals.forEach((intervalItem: ComplexMultiplier) => {
            const maxInterval = intervalItem.interval[1];
            const minInterval = intervalItem.interval[0];
            const multiplier = intervalItem.multiplier;

            for (
                let i = 0;
                i < (maxInterval - minInterval) / multiplier;
                i += 1
            ) {
                if (
                    minInterval + i * multiplier >= min &&
                    minInterval + i * multiplier <= max
                ) {
                    complexInterval?.push(minInterval + i * multiplier);
                }
            }
        });

        return complexInterval;
    },

    polygonHelper(area: number[][]): string {
        let areaValue = 'POLYGON((';
        area.forEach((item: number[], index: number) => {
            areaValue += `${item[0]} ${item[1]}`;

            if (index + 1 < area.length) {
                areaValue += ', ';
            }
        });
        areaValue += '))';

        return areaValue;
    },

    // IMPORTANT!
    // MW has the lat lng coordiates reversed,
    // so we must also send reversed coordinates when crating a new search agent
    searchAgentPolygonHelper(area: number[][]): string {
        let areaValue = 'POLYGON((';
        area.forEach((item: number[], index: number) => {
            areaValue += `${item[1]} ${item[0]}`;

            if (index + 1 < area.length) {
                areaValue += ', ';
            }
        });
        areaValue += '))';

        return areaValue;
    },
};
