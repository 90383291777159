
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

export default {
    props: {
        alt: {
            type: String as PropType<string>,
            default: () => '',
        },
        image: {
            type: String as PropType<string>,
            default: () => '',
        },
        body: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    computed: {
        formattedUrl(): string {
            if (!this.image) return '';

            if (this.image && this.image.includes('mindworking.eu'))
                return Format.formatMindworkingUrl(
                    this.image,
                    '100',
                    '100',
                    '',
                );

            return this.image + '?height=100&width=100';
        },

        hasImage(): boolean {
            return this.formattedUrl.length > 0;
        },
    },

    methods: {
        ...mapActions({
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),
        /**
         *
         * Handle click event.
         *
         * @return {void}
         */
        onClick(): void {
            this.$store.dispatch('broker/open', '');
            this.setTrackingEntrance('Kontaktbar modul');

            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Butiksside indgang',
                    label: 'Butiksside',
                    formularIndgang: 'Kontaktbar modul',
                    formularStepnavn: 'Formularoverblik',
                    maeglerId: this.brokerId ?? '',
                },
            });
        },
    },
};
