
import { PropType } from 'vue';
import Format from '@/functions/format';
import Property from '@/interfaces/property.interface';
import Icon from '@/components/atoms/Icon.vue';

export default {
    components: {
        aIcon: Icon,
    },
    props: {
        property: {
            type: Object as PropType<Property>,
            default: () => ({
                address: '',
                city: '',
                factsDesktop: [],
                factsMobile: [],
                fallbackImageUrl: '',
                hasNewPrice: false,
                images: [],
                isDanbolig: false,
                isNew: false,
                isSold: false,
                isUnderSale: false,
                luxurious: false,
                propertySize: '',
                openHouse: '',
                openHouseSignupRequired: '',
                soldDate: new Date(),
                type: '',
                url: '',
                zipCode: 0,
            }),
        },
    },

    computed: {
        propertyStatus(): string {
            if (this.property.isSold) {
                return 'Solgt';
            }
            if (this.property.isNew) {
                return 'Nyhed!';
            }
            if (this.property.hasNewPrice) {
                return 'Ny pris!';
            }
            return '';
        },
        /**
         * Get first image.
         *
         * @return {string}
         */
        image(): string {
            if (!this.property.images?.length) {
                return this.property.fallbackImageUrl;
            }
            const image = this.property.images[0];
            return image && image.includes('mindworking.eu')
                ? Format.formatMindworkingUrl(image ?? '', '600', '')
                : image ?? this.property.fallbackImageUrl;
        },

        /**
         * Check if property is external.
         *
         * @return {boolean}
         */
        isExternal(): boolean {
            return !this.property.isDanbolig;
        },

        soldDate(): string {
            if (
                this.property.soldDate !== null &&
                this.property.soldDate !== undefined
            ) {
                const date = new Date(this.property.soldDate);
                const month = Format.monthShort(date);
                const year = Format.year(date);
                return `Solgt ${month} ${year}`;
            }

            return '';
        },
    },
};
