
import { PropType } from 'vue';
import Swiper, { Pagination } from 'swiper';
import { EventBus } from '@/functions/eventBus';
import GalleryItem from '@/interfaces/galleryItem.interface';

interface Data {
    currentIndex: number;
    isDesktop: boolean;
    swiper: Swiper | null;
}

export default {
    props: {
        images: {
            type: Array as PropType<GalleryItem[]>,
            default: () => [],
        },
        leftAligned: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        detailsId: {
            type: Number as PropType<number>,
            default: () => 0,
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            isDesktop: EventBus.isDesktop,
            swiper: null,
        };
    },

    computed: {
        currentSrc(): string {
            return this.images[this.currentIndex]?.url;
        },

        currentImage(): GalleryItem {
            return this.images[this.currentIndex];
        },
    },

    mounted(): void {
        this.initilizeSwiper();
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    methods: {
        initilizeSwiper(): void {
            this.swiper = new Swiper(this.$refs.swiper as HTMLDivElement, {
                // configure Swiper to use modules
                // modules: [Navigation, Pagination],
                modules: [Pagination],
                // Optional parameters
                loop: false,
                autoHeight: true,

                // If we need pagination
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },

                // Navigation arrows
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },

                // And if we need scrollbar
                // scrollbar: {
                //     el: '.swiper-scrollbar',
                // },
            });
        },

        /**
         * Increment index.
         *
         * @return {void}
         */
        next(): void {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex += 1;
            } else {
                this.currentIndex = 0;
            }
        },

        /**
         * Decrease index.
         *
         * @return {void}
         */
        prev(): void {
            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
            } else {
                this.currentIndex = this.images.length - 1;
            }
        },
    },
};
