
import { PropType } from 'vue';
import Employee from '@/interfaces/employee.interface';
import Format from '@/functions/format';

export default {
    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                address: '',
                brokerId: '',
                brokerName: '',
                city: '',
                description: '',
                employeeId: '',
                email: '',
                imageUrl: '',
                linkedIn: '',
                name: '',
                phone: '',
                title: '',
                type: '',
                videoUrl: '',
                zipCode: '',
            }),
        },
    },

    computed: {
        formattedUrl(): string {
            return this.employee?.imageUrl &&
                this.employee?.imageUrl.includes('mindworking.eu')
                ? Format.formatMindworkingUrl(
                      this.employee?.imageUrl ?? '',
                      '600',
                      '',
                  )
                : this.employee?.imageUrl ?? '';
        },
    },
};
