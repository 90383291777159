
import { PropType } from 'vue';

interface Icon {
    id: number;
    url: string;
    name: string;
    relativeUrl: string;
    altText?: string;
    umbracoWidth: string;
    umbracoHeight: string;
    center: string[];
    mediaCrops: string[];
}

interface UspItem {
    icon: Icon;
    name: string;
    headline: string;
    text: string;
}

export default {
    props: {
        headline: {
            type: String as PropType<string>,
            default: () => '',
        },
        icon: {
            type: String as PropType<string>,
            default: () => '',
        },
        image: {
            type: String as PropType<string>,
            default: () => '',
        },
        imageWidth: {
            type: String as PropType<string>,
            default: () => '',
        },
        imageHeight: {
            type: String as PropType<string>,
            default: () => '',
        },
        imageCenter: {
            type: String as PropType<string>,
            default: () => '',
        },
        focalPoint: {
            type: String as PropType<string>,
            default: () => '',
        },
        uspItems: {
            type: Array as PropType<UspItem[]>,
            default: () => [],
        },
    },

    mounted(): void {
        const nodes = [];
        const container = this.$refs.container as HTMLDivElement;

        if (
            container.classList.contains('no-headline') &&
            container.classList.contains('has-icon')
        ) {
            nodes.push(container);

            const myObserver = new ResizeObserver(
                (entries: ResizeObserverEntry[]) => {
                    entries.forEach((entry: ResizeObserverEntry) => {
                        const el = entry.target as HTMLDivElement;
                        const iconElement = entry.target.querySelector(
                            '.o-sectionHeroWithUsp__icon',
                        ) as HTMLImageElement;
                        const iconHeight =
                            parseInt(
                                window
                                    .getComputedStyle(iconElement)
                                    .height.replace('px', ''),
                            ) / 2;
                        el.style.setProperty('--iconHeight', `${iconHeight}px`);
                    });
                },
            );

            nodes.forEach((el) => {
                myObserver.observe(el);
            });
        }
    },
};
