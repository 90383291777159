
import { PropType } from 'vue';
import mSkeletonLoader from '@/components/molecules/SkeletonLoader.vue';

interface Data {
    isLoading: boolean;
}

export default {
    name: 'm-image-gallery-thumbnail',
    components: {
        mSkeletonLoader,
    },
    props: {
        url: {
            type: String as PropType<string>,
            default: () => '',
        },
        width: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        height: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        index: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        alt: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            isLoading: true,
        };
    },

    methods: {
        onLoad(): void {
            this.isLoading = false;
        },
    },
};
