
import { PropType, isProxy, toRaw } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import Property from '@/interfaces/property.interface';
import { mapGetters } from 'vuex';
import oPropertyPreviewFavoriteButton from '@/components/organisms/property/PropertyPreviewFavoriteButton.vue';

interface Data {
    currentDirection: string;
    currentSlide: number;
    isMultiline: boolean;
    uuid: string;
}

interface OpenHouseTexts {
    teaser: string;
    teaserSignup: string;
}

export default {
    components: {
        oPropertyPreviewFavoriteButton,
    },
    props: {
        addedClasses: {
            type: String as PropType<string>,
            default: () => '',
        },
        index: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        property: {
            type: Object as PropType<Property>,
            default: () => ({
                address: '',
                brokerId: '',
                city: '',
                factsDesktop: [],
                factsMobile: [],
                fallbackImageUrl: '',
                hasNewPrice: false,
                images: [],
                isDanbolig: false,
                isNew: false,
                isSold: false,
                isUnderSale: false,
                luxurious: false,
                openHouse: '',
                openHouseSignupRequired: false,
                propertyId: '',
                soldDate: new Date(),
                spotText: '',
                type: '',
                url: '',
                zipCode: 0,
            }),
        },
        openHouseTexts: {
            type: Object as PropType<OpenHouseTexts>,
            default: () => ({
                teaser: '',
                teaserSignup: '',
            }),
        },
        total: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
        isPin: {
            type: Boolean as PropType<boolean | null>,
            default: () => false,
        },
        updateCurrentSlide: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            currentSlide: 0,
            isMultiline: false,
            uuid: '',
        };
    },

    watch: {
        updateCurrentSlide: {
            handler() {
                this.currentSlide = 0;
            },
        },
    },

    computed: {
        /**
         * Check if property is external.
         *
         * @return {boolean}
         */
        isExternal(): boolean {
            return !this.property.isDanbolig;
        },

        openHouseFormatted(): string {
            const template = this.property.openHouseSignupRequired
                ? this.openHouseTexts.teaserSignup
                : this.openHouseTexts.teaser;
            return template.replace(
                '#naesteaabenthus#',
                this.property.openHouse ?? '',
            );
        },

        soldDate(): string {
            if (
                this.property.soldDate !== null &&
                this.property.soldDate !== undefined
            ) {
                const date = new Date(this.property.soldDate);
                const month = Format.monthLong(date);
                const year = Format.year(date);
                return `Solgt ${month} ${year}`;
            }

            return '';
        },
    },

    mounted(): void {
        this.uuid = `guid-${uuidv4()}`;
        EventBus.$on('app.resize', this.calculateHeight);
        this.calculateHeight();
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        /**
         * Calculate if street name is multiline.
         *
         * @return {void}
         */
        calculateHeight(): void {
            const element = this.$refs.street as HTMLSpanElement;

            if (element !== undefined) {
                const elementHeight = element.offsetHeight;
                const elementLineHeight = parseInt(
                    window
                        .getComputedStyle(element, null)
                        .getPropertyValue('line-height'),
                    10,
                );

                this.isMultiline = elementHeight > elementLineHeight;
            }
        },

        /**
         * Make sure to use Mindworkings Image Url resize methods
         *
         * @return {string}
         */
        formattedImageUrl(image: string): string {
            return image && image.includes('mindworking.eu')
                ? Format.formatMindworkingUrl(image ?? '', '900', '')
                : image ?? this.property.fallbackImageUrl;
        },

        /**
         * Go to the next slide.
         *
         * @return {void}
         */
        nextSlide(): void {
            if (this.property.images) {
                this.currentDirection = 'next';

                if (this.currentSlide < this.property.images.length - 1) {
                    this.currentSlide += 1;
                } else {
                    this.currentSlide = 0;
                }
            }

            tracking.track(
                'trackBoligsoegning',
                'Boligsoegning',
                'Galleri skift',
                '',
            );
        },

        /**
         * Handle "click" event on next button for multiple properties.
         *
         * @return {void}
         */
        onClickNext(): void {
            this.currentSlide = 0;
            if (typeof this.total === 'number') {
                let index = this.index + 1;

                if (index >= this.total) {
                    index = 0;
                }

                this.$emit('next', index);
            }
        },

        /**
         * Handle "click" event on prev button for multiple properties.
         *
         * @return {void}
         */
        onClickPrev(): void {
            this.currentSlide = 0;
            if (typeof this.total === 'number') {
                let index = this.index - 1;

                if (index < 0) {
                    index = this.total - 1;
                }

                this.$emit('prev', index);
            }
        },

        /**
         * Go to the previous slide.
         *
         * @return {void}
         */
        previousSlide(): void {
            if (this.property.images) {
                this.currentDirection = 'prev';

                if (this.currentSlide > 0) {
                    this.currentSlide -= 1;
                } else {
                    this.currentSlide = this.property.images.length - 1;
                }
            }
            tracking.track(
                'trackBoligsoegning',
                'Boligsoegning',
                'Galleri skift',
                '',
            );
        },

        onClick(propertyId: string, trackingPage: string): void {
            let previewText = this.isPin ? 'Pin paa kort' : 'Liste';
            switch (trackingPage) {
                case 'broker':
                    tracking.track(
                        'trackButiksside',
                        'Butiksside',
                        'Boliger til salg',
                        propertyId ?? 'Ukendt bolig ID',
                    );
                    break;
                case 'neighborhood':
                    tracking.track(
                        'trackNabolag',
                        'Nabolag',
                        'Boliger til salg',
                        propertyId ?? 'Ukendt bolig ID',
                    );
                    break;
                case 'property':
                    tracking.track(
                        'trackBoligpraesentation',
                        'Boligpraesentation',
                        'Boliger til salg',
                        propertyId ?? 'Ukendt bolig ID',
                    );
                    break;
                case 'propertysearch':
                    tracking.track(
                        'trackBoligsoegning',
                        'Boligsoegning',
                        `Bolig preview klik - ${previewText}`,
                        propertyId ?? 'Ukendt bolig ID',
                    );
                    break;
                default:
            }
        },
    },
};
