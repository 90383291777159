
import { PropType } from 'vue';
import Employee from '@/interfaces/employee.interface';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

export default {
    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                address: '',
                brokerId: '',
                brokerName: '',
                city: '',
                description: '',
                employeeId: '',
                email: '',
                imageUrl: '',
                linkedIn: '',
                name: '',
                phone: '',
                title: '',
                type: '',
                videoUrl: '',
                zipCode: '',
            }),
        },

        showBlueButtons: {
            type: Boolean as PropType<Boolean>,
            default: () => true,
        },

        ctaText: {
            type: String as PropType<string>,
            default: () => 'Skriv til mig',
        },
    },

    computed: {
        hasVideo(): boolean {
            return (
                this.employee.videoUrl !== '' && this.employee.videoUrl !== null
            );
        },

        hasDescription(): boolean {
            return (
                this.employee.description !== '' &&
                this.employee.description !== null
            );
        },

        formattedUrl(): string {
            return this.employee?.imageUrl &&
                this.employee?.imageUrl.includes('mindworking.eu')
                ? Format.formatMindworkingUrl(
                      this.employee?.imageUrl ?? '',
                      '600',
                      '600',
                      'crop',
                  )
                : this.employee?.imageUrl ?? '';
        },
    },

    methods: {
        showEmployeeInfo(e: any): void {
            const id = e.target.closest('button').dataset.employeeid;
            tracking.track(
                'trackButiksside',
                'Butiksside',
                'Maegler klikket',
                this.employee.name ?? '',
            );
            this.$emit('clicked', { id, panel: 'info', track: false });
        },

        showEmployeeForm(e: any): void {
            const id = e.target.closest('button').dataset.employeeid;
            this.$emit('clicked', { id, panel: 'form', track: true });
        },
    },
};
