import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "o-employeeShopCard__wrapper" }
const _hoisted_3 = ["data-employeeid"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "m-button o-employeeShopCard__actionButton"
}
const _hoisted_6 = {
  key: 1,
  class: "m-button o-employeeShopCard__actionButton"
}
const _hoisted_7 = { class: "o-employeeShopCard__content" }
const _hoisted_8 = ["data-employeeid"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "o-employeeShopCard__info" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["href", "textContent"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["data-employeeid"]
const _hoisted_17 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["o-employeeShopCard", {
            'o-employeeShopCard--isOwner':
                $props.employee.type !== '' && $props.employee.type !== 'Medarbejder',
        }])
  }, [
    _createElementVNode("div", null, [
      ($props.employee.type !== '' && $props.employee.type !== 'Medarbejder')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "o-employeeShopCard__tag",
            textContent: _toDisplayString($props.employee.type)
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "o-employeeShopCard__portrait",
          "data-employeeid": $props.employee.employeeId,
          "aria-label": "Vis mæglerinformation",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.showEmployeeInfo && $options.showEmployeeInfo(...args)))
        }, [
          _createElementVNode("img", {
            src: $options.formattedUrl,
            loading: "lazy",
            class: "o-employeeShopCard__image",
            width: "320",
            height: "320",
            alt: "Billede af mægleren"
          }, null, 8 /* PROPS */, _hoisted_4),
          ($props.showBlueButtons && $options.hasVideo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createVNode(_component_a_icon, { name: "play" })
              ]))
            : _createCommentVNode("v-if", true),
          ($props.showBlueButtons && $options.hasDescription && !$options.hasVideo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createVNode(_component_a_icon, { name: "plus" })
              ]))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_3),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "button",
            "data-employeeid": $props.employee.employeeId,
            "aria-label": "Vis mæglerinformation",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.showEmployeeInfo && $options.showEmployeeInfo(...args)))
          }, [
            _createElementVNode("span", {
              class: "a-lead o-employeeShopCard__name",
              textContent: _toDisplayString($props.employee.name)
            }, null, 8 /* PROPS */, _hoisted_9),
            ($props.employee.title)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "a-label o-employeeShopCard__title",
                  textContent: _toDisplayString($props.employee.title)
                }, null, 8 /* PROPS */, _hoisted_10))
              : _createCommentVNode("v-if", true)
          ], 8 /* PROPS */, _hoisted_8),
          _createElementVNode("div", _hoisted_11, [
            ($props.employee.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("a", {
                    href: `tel:${$props.employee.phone}`,
                    class: "a-anchor",
                    textContent: _toDisplayString($props.employee.phone)
                  }, null, 8 /* PROPS */, _hoisted_13)
                ]))
              : _createCommentVNode("v-if", true),
            ($props.employee.linkedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("a", {
                    href: $props.employee.linkedIn,
                    class: "a-anchor",
                    target: "_blank",
                    rel: "noopener"
                  }, "LinkedIn", 8 /* PROPS */, _hoisted_15)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("button", {
            class: "m-button m-button--outlined",
            "data-cta": "",
            "data-employeeid": $props.employee.employeeId,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.showEmployeeForm && $options.showEmployeeForm(...args)))
          }, [
            _createElementVNode("span", {
              class: "m-button__text",
              textContent: _toDisplayString($props.ctaText)
            }, null, 8 /* PROPS */, _hoisted_17)
          ], 8 /* PROPS */, _hoisted_16)
        ])
      ])
    ])
  ], 2 /* CLASS */))
}