
import { PropType } from 'vue';
import { EventBus } from '../../../functions/eventBus';
import Format from '../../../functions/format';
import FloorplanHotspot from '../../../interfaces/floorplanHotspot.interface';
import GalleryItem from '../../../interfaces/galleryItem.interface';
import InteractiveFloorplan from '../../../interfaces/interactiveFloorplan.interface';
import mLoader from '../../molecules/Loader.vue';
import mMultiToggle from '../../molecules/form/MultiToggle.vue';
import mSelect from '../../molecules/form/Select.vue';
import KeyValue from '../../../interfaces/keyValue.interface';

interface SelectItem {
    label: string;
    value: string;
}

interface Data {
    currentType: string;
    floorplanOptions: {
        selectedItem: string;
        options: SelectItem[];
    };
    hotspotIndex: number;
    hotspots: FloorplanHotspot[];
    imagesActive: boolean;
    isDesktop: boolean;
    isLoading: boolean;
    isZoomed: boolean;
    imageError: boolean;
    types: KeyValue[];
}

interface Texts {
    interactive: string;
    threeDimensional: string;
    twoDimensional: string;
}

export default {
    components: {
        mLoader,
        mMultiToggle,
        mSelect,
    },
    props: {
        headline: {
            type: String as PropType<string>,
            default: () => '',
        },
        propertyId: {
            type: String as PropType<string>,
            default: () => '',
        },
        floorplan2d: {
            type: Array as PropType<GalleryItem[]>,
            default: () => [],
        },
        floorplan3d: {
            type: Array as PropType<GalleryItem[]>,
            default: () => [],
        },
        floorplanInteractive: {
            type: Array as PropType<InteractiveFloorplan[]>,
            default: () => [],
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                interactive: '',
                threeDimensional: '',
                twoDimensional: '',
            }),
        },
    },

    data(): Data {
        return {
            currentType: '',
            floorplanOptions: {
                selectedItem: '',
                options: [],
            },
            hotspotIndex: 0,
            hotspots: [],
            imagesActive: false,
            isDesktop: EventBus.isDesktop,
            isLoading: false,
            isZoomed: false,
            imageError: false,
            types: [],
        };
    },

    computed: {
        currentHotspot(): FloorplanHotspot {
            return this.hotspots[this.hotspotIndex];
        },

        showMultiToggle(): boolean {
            return this.types.length > 1;
        },

        showOptionsBox(): boolean {
            return (
                this.types.length > 1 ||
                this.floorplanOptions.options.length > 1
            );
        },
    },

    watch: {
        floorplanOptions: {
            deep: true,
            handler() {
                if (this.currentType === 'interactive') {
                    this.setCurrentHotspots();
                } else {
                    this.hotspots = [];
                }
            },
        },
    },

    mounted(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });

        this.loadTypes();
    },

    methods: {
        zoom(e: MouseEvent | TouchEvent) {
            let zoomer: HTMLElement = e.currentTarget as HTMLElement;
            let offsetX = 0;
            let offsetY = 0;

            if (e instanceof MouseEvent) {
                offsetX = e.offsetX;
                offsetY = e.offsetY;
            } else if (e instanceof TouchEvent) {
                offsetX = e.touches[0].pageX;
                offsetY = e.touches[0].pageY;
            }

            const x = (offsetX / zoomer.offsetWidth) * 100;
            const y = (offsetY / zoomer.offsetHeight) * 100;
            zoomer.style.backgroundPosition = `${x}% ${y}%`;
        },

        showHotspotDirection(index: number): boolean {
            return (
                this.hotspots.length > 0 &&
                this.currentHotspot.data.deg !== -1 &&
                this.hotspotIndex === index
            );
        },

        /**
         * Load the floorplan types for the property.
         *
         * @return {void}
         */
        loadTypes(): void {
            if (this.floorplan2d && this.floorplan2d.length > 0) {
                this.types = [
                    ...this.types,
                    { key: 'twoDimensional', value: this.texts.twoDimensional },
                ];
            }

            if (this.floorplan3d && this.floorplan3d.length > 0) {
                this.types = [
                    ...this.types,
                    {
                        key: 'threeDimensional',
                        value: this.texts.threeDimensional,
                    },
                ];
            }

            if (
                this.floorplanInteractive &&
                this.floorplanInteractive.length > 0
            ) {
                this.types = [
                    ...this.types,
                    { key: 'interactive', value: this.texts.interactive },
                ];
            }

            if (this.types.length > 0) {
                this.changeType(this.types[0].key);
            }
        },

        changeType(payload: string): void {
            this.imagesActive = false;
            this.currentType = payload;
            this.isZoomed = false;

            if (
                payload === 'twoDimensional' ||
                payload === 'threeDimensional'
            ) {
                this.loadFloorplans();
            } else {
                this.loadInteractiveFloorplans();
            }

            this.$nextTick(() => {
                let select = document.querySelector(
                    '.floorplanSelect select',
                ) as HTMLSelectElement;
                if (select) {
                    select.focus();
                    select.blur();
                }
            });
        },

        /**
         * Load 2D or 3D floorplans.
         *
         * @return {void}
         */
        loadFloorplans(): void {
            this.floorplanOptions.options = this.mapFloorplans(
                this.currentType === 'twoDimensional'
                    ? this.floorplan2d
                    : this.floorplan3d,
            );
            this.setFirstFloorplan();
        },

        mapFloorplans(media: GalleryItem[]): SelectItem[] {
            return media.map((mediaItem: GalleryItem) => {
                return {
                    label: mediaItem.description,
                    value: mediaItem.url,
                };
            });
        },

        /**
         * Load the interactive floorplans.
         *
         * @return {void}
         */
        loadInteractiveFloorplans(): void {
            this.floorplanOptions.options = this.floorplanInteractive.map(
                (i: InteractiveFloorplan) => {
                    return {
                        label: i.name,
                        value: i.file,
                    };
                },
            );

            this.setFirstFloorplan();
            this.setCurrentHotspots();
        },

        setFirstFloorplan(): void {
            if (this.floorplanOptions.options.length > 0) {
                this.swapImage(this.floorplanOptions.options[0].value);
                this.floorplanOptions.selectedItem =
                    this.floorplanOptions.options[0].value;
            }
        },

        changeFloorplan(e: any): void {
            this.swapImage(e.target.value);
            this.isZoomed = false;

            if (this.currentType === 'interactive') {
                this.hotspotIndex = 0;
                this.scrollHotspotImageIntoView();
            }
        },

        setCurrentHotspots(): void {
            const currentFloorPlan = this.floorplanInteractive.find(
                (i: InteractiveFloorplan) =>
                    i.file === this.floorplanOptions.selectedItem,
            );

            if (currentFloorPlan && currentFloorPlan.hotspots !== undefined) {
                this.buildHotspots(currentFloorPlan.hotspots);
                this.hotspotIndex = 0;
                this.scrollHotspotImageIntoView();
            }
        },

        buildHotspots(currentHotspots: FloorplanHotspot[]): void {
            this.hotspots = [];

            if (currentHotspots && currentHotspots.length > 0) {
                currentHotspots.forEach((hotspot: FloorplanHotspot) => {
                    let deg = -1;
                    if (hotspot.data.deg) {
                        deg = Number(hotspot.data.deg) + 180;
                    }

                    this.hotspots.push({
                        id: hotspot.id,
                        data: {
                            x: hotspot.data.x,
                            y: hotspot.data.y,
                            deg,
                        },
                        file: Format.formatMindworkingUrl(
                            hotspot.file,
                            '',
                            '900',
                        ),
                    });
                });
            }

            this.imagesActive = this.hotspots.length > 0;
        },

        hotspotClick(e: Event): void {
            const b = e.target as HTMLButtonElement;
            if (b.dataset.index) {
                this.hotspotIndex = parseInt(b.dataset.index);
                this.scrollHotspotImageIntoView();
            }
        },

        /**
         * Go to the next hotspot
         *
         * @return {void}
         */
        nextHotspot(): void {
            if (this.hotspotIndex === this.hotspots.length - 1) {
                this.hotspotIndex = 0;
            } else {
                this.hotspotIndex += 1;
            }

            this.scrollHotspotImageIntoView();
        },

        /**
         * Go to the previous hotspot.
         *
         * @return {void}
         */
        prevHotspot(): void {
            if (this.hotspotIndex === 0) {
                this.hotspotIndex = this.hotspots.length - 1;
            } else {
                this.hotspotIndex -= 1;
            }

            this.scrollHotspotImageIntoView();
        },

        scrollHotspotImageIntoView(): void {
            const wrapper = this.$refs.hotspotImages as HTMLDivElement;
            const list = this.$refs.hotspotImageList as HTMLDivElement;

            const images = document.querySelectorAll(
                '[id^="hotspot-"]',
            ) as NodeListOf<HTMLImageElement>; // eslint-disable-line
            Promise.all(
                Array.from(images)
                    .filter((img) => !img.complete)
                    .map(
                        (img) =>
                            new Promise((resolve) => {
                                img.onload = img.onerror = resolve;
                            }),
                    ),
            ).then(() => {
                // eslint-disable-line
                const image = document.getElementById(
                    `hotspot-${this.hotspotIndex}`,
                ) as HTMLImageElement;

                if (wrapper && list && image) {
                    const wrapperWidth = wrapper.getBoundingClientRect().width;
                    const imageWidth = image.getBoundingClientRect().width;

                    let translation = -image.offsetLeft;
                    if (this.hotspotIndex === this.hotspots.length - 1) {
                        translation += wrapperWidth - imageWidth;
                    } else if (this.hotspotIndex !== 0) {
                        translation += (wrapperWidth - imageWidth) / 2;
                    }

                    list.style.transform = `translateX(${translation}px)`;

                    if (this.isDesktop) {
                        wrapper.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                        });
                    }
                }
            });
        },

        swapImage(payload: string): void {
            this.$nextTick(() => {
                this.isLoading = true;

                const img = this.$refs.floorplanImage as HTMLImageElement;

                img.onload = () => {
                    this.isLoading = false;
                    this.imageError = false;
                };

                img.onerror = () => {
                    this.isLoading = false;
                    this.imageError = true;
                };

                try {
                    img.src = Format.formatMindworkingUrl(payload, '300', '');
                    img.srcset = `${Format.formatMindworkingUrl(
                        payload,
                        '300',
                        '',
                    )} 300w, ${Format.formatMindworkingUrl(
                        payload,
                        '600',
                        '',
                    )} 600w, ${Format.formatMindworkingUrl(
                        payload,
                        '900',
                        '',
                    )} 900w, ${Format.formatMindworkingUrl(
                        payload,
                        '1200',
                        '',
                    )} 1200w`;
                } catch (e) {
                    this.isLoading = false;
                }
            });
        },
    },
};
